import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from '../../components/project_page/ProjectPageWrapper';
import TextBlock from '../../components/project_page/TextBlock';
import Mobile3 from '../../components/project_page/Mobile3';
import Web1 from '../../components/project_page/Web1';
import Image1 from '../../components/project_page/Image1';
import ImageGrid from '../../components/project_page/ImageGrid';
import RollCallVid from "../../videos/jjacks.mp4"
import VideoWrap from '../../components/project_page/image_wrappers/VideoWrap';

import globalStyles from '../global.module.css';

const headerBackground = "#fff7eb"
const navColor="#222";
const HereNow = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="HereNow"
      oneLiner="Powering shared experiences"
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Collaboration"
          headline={`Automating an IRL experience platform`}
          content={<><p>
            We worked with HereNow, a company that designs unique IRL
            experiences where people can spark new friendly or romantic relationships,
            to automate their user management, matching, and feedback collection.
            </p><p>
            Their updated platform enables them to run significantly more events with
            reduced manual, time-consuming, effort.
          </p></>}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Mobile3
          one={<Img fluid={data.body1.childImageSharp.fluid} />}
          two={<Img fluid={data.body2.childImageSharp.fluid} />}
          three={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
        <Web1
          color={"rgb(220 222 225)"}
          desktopImage={<Img fluid={data.body6.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body4.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomHuge}`}>
        <Web1
          color={"rgb(220 222 225)"}

          desktopImage={<Img fluid={data.body7.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body5.childImageSharp.fluid} />}
        />
      </div>

      <div className={``}>
        <div className={`${globalStyles.flex} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}>
          <div className={`${globalStyles.flexGrow} ${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}>
            <TextBlock
              title="Engineering"
              headline={`Integrations`}
              content={<><p>We pulled together HereNow's existing usage of Airtable
              and Eventbrite and added a Twilio integration, all running through their
              new centralized customer and event CRM.
                </p></>
              }
            />
          </div>
          <div className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}>
            <Image1 img={<Img fluid={data.body8.childImageSharp.fluid} />} />
          </div>
        </div>
      </div>
    </ProjectPageWrapper>
  </Layout>
)

export default HereNow

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/herenow/header.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mobileHeader: file(relativePath: { eq: "projects/herenow/header-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body1: file(relativePath: { eq: "projects/herenow/phone1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body2: file(relativePath: { eq: "projects/herenow/phone2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body3: file(relativePath: { eq: "projects/herenow/phone3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body4: file(relativePath: { eq: "projects/herenow/web2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body5: file(relativePath: { eq: "projects/herenow/web4.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body6: file(relativePath: { eq: "projects/herenow/web1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body7: file(relativePath: { eq: "projects/herenow/web3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body8: file(relativePath: { eq: "projects/herenow/Integrations.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
